import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../Api/api'

const baseRoute = 'vehicle-details'


//Manufacturers
export const fetchManufacturers = createAsyncThunk('manufacturers/fetchManufacturers', async (data) => {

    const response = await api.get(`${baseRoute}/manufacturers?page=${parseInt(data.page)}&limit=${parseInt(data.limit)}`);
    return response.data;

});


export const fetchOneManufacturer = createAsyncThunk('manufacturers/fetchOneManufacturer', async (data) => {

    const response = await api.get(`${baseRoute}/manufacturers/${data.id}`);
    return response.data;

});



export const createManufacturer = createAsyncThunk('manufacturers/createManufacturer', async (data) => {

    const response = await api.post(`${baseRoute}/manufacturers`, data);
    return response.data;

});



export const deleteManufacturer = createAsyncThunk('manufacturers/deleteManufacturer', async (data) => {

    const response = await api.delete(`${baseRoute}/manufacturers/${data.id}`);
    return response.data;

});



export const updateManufacturer = createAsyncThunk('manufacturers/updateManufacturers', async (data) => {

    const response = await api.put(`${baseRoute}/manufacturers/${data.id}`, data.body);
    return response.data;

});


// -------------------------------------------------


// Models
export const fetchModels = createAsyncThunk('models/fetchModels', async (data) => {

    const response = await api.get(`${baseRoute}/models?page=${parseInt(data.page)}&limit=${parseInt(data.limit)}`);
    return response.data;

});

export const fetchModelsOfAManufacturer = createAsyncThunk('models/fetchModelsOfAManufacturer', async (data) => {

    const response = await api.get(`${baseRoute}/manufacturers/${data.id}/models`);
    return response.data;

});

export const createModel = createAsyncThunk('models/createModel', async (data) => {

    const response = await api.post(`${baseRoute}/models`, data);
    return response.data;

});



export const deleteModel = createAsyncThunk('models/deleteModel', async (data) => {

    const response = await api.delete(`${baseRoute}/models/${data.id}`);
    return response.data;

});



export const fetchOneModel = createAsyncThunk('model/fetchOneModel', async (data) => {

    const response = await api.get(`${baseRoute}/models/${data.id}`);
    return response.data;

});



export const updateModel = createAsyncThunk('model/updateModels', async (data) => {

    const response = await api.put(`${baseRoute}/models/${data.id}`, data.body);
    return response.data;

});

//  -----------------------------------------------

// Item Finish

export const fetchItemFinish = createAsyncThunk('item-finish/fetchItemFinish', async (data) => {

    const response = await api.get(`${baseRoute}/item-finish?page=${parseInt(data.page)}&limit=${parseInt(data.limit)}`);
    return response.data;

});


export const createItemFinish = createAsyncThunk('item-finish/createItemFinish', async (data) => {

    const response = await api.post(`${baseRoute}/item-finish`, data);
    return response.data;

});



export const deleteItemFinish = createAsyncThunk('item-finish/deleteItemFinish', async (data) => {

    const response = await api.delete(`${baseRoute}/item-finish/${data.id}`);
    return response.data;

});



export const fetchOneItemFinish = createAsyncThunk('item-finish/fetchOneItemFinish', async (data) => {

    const response = await api.get(`${baseRoute}/item-finish/${data.id}`);
    return response.data;

});



export const updateItemFinish = createAsyncThunk('item-finish/updateItemFinishs', async (data) => {

    const response = await api.put(`${baseRoute}/item-finish/${data.id}`, data.body);
    return response.data;

});


// Colour

export const fetchColours = createAsyncThunk('colours/fetchColours', async (data) => {

    const response = await api.get(`${baseRoute}/colours?page=${parseInt(data.page)}&limit=${parseInt(data.limit)}`);
    return response.data;

});


export const createColour = createAsyncThunk('colours/createColour', async (data) => {

    const response = await api.post(`${baseRoute}/colours`, data);
    return response.data;

});



export const deleteColour = createAsyncThunk('colour/deleteColour', async (data) => {

    const response = await api.delete(`${baseRoute}/colours/${data.id}`);
    return response.data;

});



export const fetchOneColour = createAsyncThunk('colours/fetchOneColour', async (data) => {

    const response = await api.get(`${baseRoute}/colours/${data.id}`);
    return response.data;

});



export const updateColour = createAsyncThunk('colours/updateColour', async (data) => {

    const response = await api.put(`${baseRoute}/colours/${data.id}`, data.body);
    return response.data;

});



const vehcileSlice = createSlice({
    name: 'vehicles',
    initialState: {
        fetchManufacturers: {},
        fetchOneManufacturer: {},
        createManufacturer: {},
        deleteManufacturer: {},
        updateManufacturer: {},
        // -------------------
        fetchModels:{},
        createModel:{},
        deleteModel:{},
        fetchOneModel:{},
        updateModel:{},
        fetchModelsOfAManufacturer:{},
        // ------------------
        fetchItemFinish:{},
        createItemFinish:{},
        deleteItemFinish:{},
        fetchOneItemFinish:{},
        updateItemFinish:{},
        // ------------------
        fetchColours:{},
        createColour:{},
        deleteColour:{},
        fetchOneColour:{},
        updateColour:{},

        status: {
            // Manufacturers
            fetchManufacturers: 'idle',
            fetchOneManufacturer: 'idle',
            createManufacturer: 'idle',
            deleteManufacturer: 'idle',
            updateManufacturer: 'idle',
            // -----------------------
            // Models
            fetchModels:"idle",
            createModel:"idle",
            deleteModel:"idle",
            fetchOneModel:"idle",
            updateModel:"idle",
            // ----------------------
            // Item finishes
            fetchItemFinish:"idle",
            createItemFinish:"idle",
            deleteItemFinish:"idle",
            fetchOneItemFinish:"idle",
            updateItemFinish:"idle",
            // -----------------------
            // Colours
            fetchColours:"idle",
            createColour:"idle",
            deleteColour:"idle",
            fetchOneColour:"idle",
            updateColour:"idle",
            fetchModelsOfAManufacturer:"idle"
        },
        error: null,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchManufacturers.pending, (state) => {
                state.status.fetchManufacturers = 'loading';
                state.error = null;
            })
            .addCase(fetchManufacturers.fulfilled, (state, action) => {
                state.status.fetchManufacturers = 'success';
                state.fetchManufacturers = action.payload;
                state.error = null;
            })
            .addCase(fetchManufacturers.rejected, (state, action) => {
                state.status.fetchManufacturers = 'failed';
                state.error = action.error.message;
            })
            .addCase(createManufacturer.pending, (state) => {
                state.status.createManufacturer = 'loading';
                state.error = null;
            })
            .addCase(createManufacturer.fulfilled, (state, action) => {
                state.status.createManufacturer = 'success';
                state.createManufacturer = action.payload;
                state.error = null;
            })
            .addCase(createManufacturer.rejected, (state, action) => {
                state.status.createManufacturer = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateManufacturer.pending, (state) => {
                state.status.updateManufacturer = 'loading';
                state.error = null;
            })
            .addCase(updateManufacturer.fulfilled, (state, action) => {
                state.status.updateManufacturer = 'success';
                state.updateManufacturer = action.payload;
                state.error = null;
            })
            .addCase(updateManufacturer.rejected, (state, action) => {
                state.status.updateManufacturer = 'failed';
                state.error = action.error.message;
            })
            .addCase(deleteManufacturer.pending, (state) => {
                state.status.deleteManufacturer = 'loading';
                state.error = null;
            })
            .addCase(deleteManufacturer.fulfilled, (state, action) => {
                state.status.deleteManufacturer = 'success';
                state.deleteManufacturer = action.payload;
                state.error = null;
            })
            .addCase(deleteManufacturer.rejected, (state, action) => {
                state.status.deleteManufacturer = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchOneManufacturer.pending, (state) => {
                state.status.fetchOneManufacturer = 'loading';
                state.error = null;
            })
            .addCase(fetchOneManufacturer.fulfilled, (state, action) => {
                state.status.fetchOneManufacturer = 'success';
                state.fetchOneManufacturer = action.payload;
                state.error = null;
            })
            .addCase(fetchOneManufacturer.rejected, (state, action) => {
                state.status.fetchOneManufacturer = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchModels.pending, (state) => {
                state.status.fetchModels = 'loading';
                state.error = null;
            })
            .addCase(fetchModels.fulfilled, (state, action) => {
                state.status.fetchModels = 'success';
                state.fetchModels = action.payload;
                state.error = null;
            })
            .addCase(fetchModels.rejected, (state, action) => {
                state.status.fetchModels = 'failed';
                state.error = action.error.message;
            })
            .addCase(createModel.pending, (state) => {
                state.status.createModel = 'loading';
                state.error = null;
            })
            .addCase(createModel.fulfilled, (state, action) => {
                state.status.createModel = 'success';
                state.createModel = action.payload;
                state.error = null;
            })
            .addCase(createModel.rejected, (state, action) => {
                state.status.createModel = 'failed';
                state.error = action.error.message;
            })
            .addCase(deleteModel.pending, (state) => {
                state.status.deleteModel = 'loading';
                state.error = null;
            })
            .addCase(deleteModel.fulfilled, (state, action) => {
                state.status.deleteModel = 'success';
                state.deleteModel = action.payload;
                state.error = null;
            })
            .addCase(deleteModel.rejected, (state, action) => {
                state.status.deleteModel = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchItemFinish.pending, (state) => {
                state.status.fetchItemFinish = 'loading';
                state.error = null;
            })
            .addCase(fetchItemFinish.fulfilled, (state, action) => {
                state.status.fetchItemFinish = 'success';
                state.fetchItemFinish = action.payload;
                state.error = null;
            })
            .addCase(fetchItemFinish.rejected, (state, action) => {
                state.status.fetchItemFinish = 'failed';
                state.error = action.error.message;
            })
            .addCase(createItemFinish.pending, (state) => {
                state.status.createItemFinish = 'loading';
                state.error = null;
            })
            .addCase(createItemFinish.fulfilled, (state, action) => {
                state.status.createItemFinish = 'success';
                state.createItemFinish = action.payload;
                state.error = null;
            })
            .addCase(createItemFinish.rejected, (state, action) => {
                state.status.createItemFinish = 'failed';
                state.error = action.error.message;
            })
            .addCase(deleteItemFinish.pending, (state) => {
                state.status.deleteItemFinish = 'loading';
                state.error = null;
            })
            .addCase(deleteItemFinish.fulfilled, (state, action) => {
                state.status.deleteItemFinish = 'success';
                state.deleteItemFinish = action.payload;
                state.error = null;
            })
            .addCase(deleteItemFinish.rejected, (state, action) => {
                state.status.deleteItemFinish = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchColours.pending, (state) => {
                state.status.fetchColours = 'loading';
                state.error = null;
            })
            .addCase(fetchColours.fulfilled, (state, action) => {
                state.status.fetchColours = 'success';
                state.fetchColours = action.payload;
                state.error = null;
            })
            .addCase(fetchColours.rejected, (state, action) => {
                state.status.fetchColours = 'failed';
                state.error = action.error.message;
            })
            .addCase(createColour.pending, (state) => {
                state.status.createColour = 'loading';
                state.error = null;
            })
            .addCase(createColour.fulfilled, (state, action) => {
                state.status.createColour = 'success';
                state.createColour = action.payload;
                state.error = null;
            })
            .addCase(createColour.rejected, (state, action) => {
                state.status.createColour = 'failed';
                state.error = action.error.message;
            })
            .addCase(deleteColour.pending, (state) => {
                state.status.deleteColour = 'loading';
                state.error = null;
            })
            .addCase(deleteColour.fulfilled, (state, action) => {
                state.status.deleteColour = 'success';
                state.deleteColour = action.payload;
                state.error = null;
            })
            .addCase(deleteColour.rejected, (state, action) => {
                state.status.deleteColour = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchModelsOfAManufacturer.pending, (state) => {
                state.status.fetchModelsOfAManufacturer = 'loading';
                state.error = null;
            })
            .addCase(fetchModelsOfAManufacturer.fulfilled, (state, action) => {
                state.status.fetchModelsOfAManufacturer = 'success';
                state.fetchModelsOfAManufacturer = action.payload;
                state.error = null;
            })
            .addCase(fetchModelsOfAManufacturer.rejected, (state, action) => {
                state.status.fetchModelsOfAManufacturer = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateModel.pending, (state) => {
                state.status.updateModel = 'loading';
                state.error = null;
            })
            .addCase(updateModel.fulfilled, (state, action) => {
                state.status.updateModel = 'success';
                state.updateModel = action.payload;
                state.error = null;
            })
            .addCase(updateModel.rejected, (state, action) => {
                state.status.updateModel = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchOneModel.pending, (state) => {
                state.status.fetchOneModel = 'loading';
                state.error = null;
            })
            .addCase(fetchOneModel.fulfilled, (state, action) => {
                state.status.fetchOneModel = 'success';
                state.fetchOneModel = action.payload;
                state.error = null;
            })
            .addCase(fetchOneModel.rejected, (state, action) => {
                state.status.fetchOneModel = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchOneColour.pending, (state) => {
                state.status.fetchOneColour = 'loading';
                state.error = null;
            })
            .addCase(fetchOneColour.fulfilled, (state, action) => {
                state.status.fetchOneColour = 'success';
                state.fetchOneColour = action.payload;
                state.error = null;
            })
            .addCase(fetchOneColour.rejected, (state, action) => {
                state.status.fetchOneColour = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateColour.pending, (state) => {
                state.status.updateColour = 'loading';
                state.error = null;
            })
            .addCase(updateColour.fulfilled, (state, action) => {
                state.status.updateColour = 'success';
                state.updateColour = action.payload;
                state.error = null;
            })
            .addCase(updateColour.rejected, (state, action) => {
                state.status.updateColour = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchOneItemFinish.pending, (state) => {
                state.status.fetchOneItemFinish = 'loading';
                state.error = null;
            })
            .addCase(fetchOneItemFinish.fulfilled, (state, action) => {
                state.status.fetchOneItemFinish = 'success';
                state.fetchOneItemFinish = action.payload;
                state.error = null;
            })
            .addCase(fetchOneItemFinish.rejected, (state, action) => {
                state.status.fetchOneItemFinish = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateItemFinish.pending, (state) => {
                state.status.updateItemFinish = 'loading';
                state.error = null;
            })
            .addCase(updateItemFinish.fulfilled, (state, action) => {
                state.status.updateItemFinish = 'success';
                state.updateItemFinish = action.payload;
                state.error = null;
            })
            .addCase(updateItemFinish.rejected, (state, action) => {
                state.status.updateItemFinish = 'failed';
                state.error = action.error.message;
            })
    }

})

export default vehcileSlice.reducer;
