import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../Api/api'


export const fetchDashboardFigures = createAsyncThunk('dashboard/fetchDashboardFigures', async () => {

    const response = await api.get(`dashboard/figures`);
    return response.data;

});


export const fetchCustomerStats = createAsyncThunk('dashboard/fetchCustomerStats', async () => {

    const response = await api.get(`dashboard/customer-charts`);
    return response.data;

});



const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        fetchDashboardFigures: null,
        fetchCustomerStats: null,
        status: {
            fetchDashboardFigures: 'idle',
            fetchCustomerStats: 'idle'
        },
        error: null,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardFigures.pending, (state) => {
                state.status.fetchDashboardFigures = 'loading';
                state.error = null;
            })
            .addCase(fetchDashboardFigures.fulfilled, (state, action) => {
                state.status.fetchDashboardFigures = 'success';
                state.fetchDashboardFigures = action.payload;
                state.error = null;
            })
            .addCase(fetchDashboardFigures.rejected, (state, action) => {
                state.status.fetchDashboardFigures = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchCustomerStats.pending, (state) => {
                state.status.fetchCustomerStats = 'loading';
                state.error = null;
            })
            .addCase(fetchCustomerStats.fulfilled, (state, action) => {
                state.status.fetchCustomerStats = 'success';
                state.fetchCustomerStats = action.payload;
                state.error = null;
            })
            .addCase(fetchCustomerStats.rejected, (state, action) => {
                state.status.fetchCustomerStats = 'failed';
                state.error = action.error.message;
            })
    },
});

export default dashboardSlice.reducer;
