import { lazy } from 'react'

// use lazy for better code splitting, a.k.a. load faster
const Dashboard = lazy(() => import('../pages/Dashboard'))
const Users = lazy(() => import('../pages/Users'))
const Customers = lazy(() => import('../pages/Customers'))
const Orders = lazy(() => import('../pages/Orders'))
const Jobs = lazy(() => import('../pages/Jobs'))
const Processes = lazy(() => import('../pages/Processes'))
const Page404 = lazy(() => import('../pages/404'))
const JobTracking = lazy(() => import('../pages/JobTracking'))
const ProcessTracking = lazy(() => import('../pages/ProcessTracking'))
const Departments = lazy(() => import('../pages/Departments'))
const PersonalProfile = lazy(() => import('../pages/PersonalProfile'))
const VehicleDetails = lazy(() => import('../pages/VehicleDetails'))

/**
 * ⚠ These are internal routes!
 * They will be rendered inside the app, using the default `containers/Layout`.
 * If you want to add a route to, let's say, a landing page, you should add
 * it to the `App`'s router, exactly like `Login`, `CreateAccount` and other pages
 * are routed.
 *
 * If you're looking for the links rendered in the SidebarContent, go to
 * `routes/sidebar.js`
 */
const routes = [
  {
    path: 'dashboard', // the url
    component: Dashboard, // view rendered
  },
  {
    path: 'customers',
    component: Customers,
  },
  {
    path: 'orders',
    component: Orders,
  },
  {
    path: 'jobs',
    component: Jobs,
  },
  {
    path: 'processes',
    component: Processes,
  },
  {
    path: '404',
    component: Page404,
  },
  {
    path: 'job-tracking',
    component: JobTracking,
  },
  {
    path: 'process-tracking',
    component: ProcessTracking,
  },
  {
    path: 'users',
    component: Users,
  },
  {
    path: 'departments',
    component: Departments,
  },
  {
    path: 'profile',
    component: PersonalProfile,
  }
  ,
  {
    path: 'vehicle-details',
    component: VehicleDetails
  }
]

export default routes
