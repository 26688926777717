/**
 * ⚠ These are used just to render the Sidebar!
 * You can include any link here, local or external.
 *
 * If you're looking to actual Router routes, go to
 * `routes/index.js`
 */
const routes = [
  {
    path: '/app/dashboard', // the url
    icon: 'HomeIcon', // the component being exported from icons/index.js
    name: 'Dashboard', // name that appear in Sidebar
  },
  {
    path: '/app/users',
    icon: 'EmployeesIcon',
    name: 'Users',
  },
  {
    path: '/app/customers',
    icon: 'PeopleIcon',
    name: 'Customers',
  },
  {
    path: '/app/departments',
    icon: 'DepartmentsIcon',
    name: 'Departments',
  },
  {
    path: '/app/orders',
    icon: 'TablesIcon',
    name: 'Orders',
  },
  {
    path: '/app/jobs',
    icon: 'TablesIcon',
    name: 'Jobs',
  },
  {
    path: '/app/processes',
    icon: 'TablesIcon',
    name: 'Processes',
  },
  {
    path: '/app/vehicle-details',
    icon: 'TablesIcon',
    name: 'Vehicle Details',
  },
  {
    icon: 'PagesIcon',
    name: 'Tracking',
    routes: [
      //Sub Menu
      {
        path: '/app/job-tracking',
        name: 'Job Tracking',
      },
      {
        path: '/app/process-tracking',
        name: 'Process Tracking',
      }
    ],
  }
]

export default routes
