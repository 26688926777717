import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../Api/api'


export const fetchOrders = createAsyncThunk('orders/fetchOrders', async (data) => {

  const response = await api.get(`orders?page=${parseInt(data.page)}&limit=${parseInt(data.limit)}`);
  return response.data;

});

export const fetchOneOrder = createAsyncThunk('orders/fetchOrder', async (data) => {

  const response = await api.get(`orders/${data.id}`);
  return response.data;

});

export const createNewOrder = createAsyncThunk('orders/createOrder', async (data) => {

  const response = await api.post(`orders`, data);
  return response.data;

});

export const updateOrder = createAsyncThunk('orders/updateOrder', async (data) => {

  const response = await api.put(`orders/${data.id}`, data.body);
  return response.data;

});

export const deleteOrder = createAsyncThunk('orders/deleteOrder', async (data) => {

  const response = await api.delete(`orders/${data.id}`);
  return response.data;

});

const orderSlice = createSlice({
  name: 'orders',
  initialState: {
    orders: [],
    newOrder: {},
    deleteOrder: {},
    order: {},
    updateOrder: {},
    status: {
      retrieveOrders: 'idle',
      createOrder: 'idle',
      deleteOrder: 'idle',
      order: 'idle',
      updateOrder: 'idle'
    },
    error: null,
  },
  reducers: {
    setDeleteOrderStatus(state, action) {
      state.status.deleteOrder = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.status.retrieveOrders = 'loading';
        state.error = null;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.status.retrieveOrders = 'success';
        state.orders = action.payload;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.status.retrieveOrders = 'failed';
        state.error = action.error.message;
      })
      .addCase(createNewOrder.pending, (state) => {
        state.status.createOrder = 'loading';
        state.error = null;
      })
      .addCase(createNewOrder.fulfilled, (state, action) => {
        state.status.createOrder = 'success';
        state.newOrder = action.payload;
      })
      .addCase(createNewOrder.rejected, (state, action) => {
        state.status.createOrder = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteOrder.pending, (state) => {
        state.status.deleteOrder = 'loading';
        state.error = null;
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        state.status.deleteOrder = 'success';
        state.newOrder = action.payload;
      })
      .addCase(deleteOrder.rejected, (state, action) => {
        state.status.deleteOrder = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchOneOrder.pending, (state) => {
        state.status.order = 'loading';
        state.error = null;
      })
      .addCase(fetchOneOrder.fulfilled, (state, action) => {
        state.status.order = 'success';
        state.order = action.payload;
      })
      .addCase(fetchOneOrder.rejected, (state, action) => {
        state.status.order = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateOrder.pending, (state) => {
        state.status.updateOrder = 'loading';
        state.error = null;
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.status.updateOrder = 'success';
        state.updateOrder = action.payload;
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.status.updateOrder = 'failed';
        state.error = action.error.message;
      })
  },
});
export const { setDeleteOrderStatus } = orderSlice.actions;

export default orderSlice.reducer;
