import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Label, Input, Button, HelperText } from '@windmill/react-ui'
import AmrcLogo from '../assets/img/amrc-logo.svg';
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import loginValidationSchema from '../components/FormValidationSchemas/LoginValidationSchema'
import { userSignIn } from '../redux/slices/userApiSlice';
import { useEffect } from 'react';
import AuthContext from '../context/AuthContext';
import { useContext } from 'react';
import BarLoader from "react-spinners/BarLoader";


function Login() {


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userSlice = useSelector((state) => state.userSlice)
  const { auth, storeAccessTokenLocally } = useContext(AuthContext);
  const from = location.state?.from?.pathname || "/app/dashboard";


  const onSubmit = (data) => {
    dispatch(userSignIn(data))
  }

  useEffect(() => {
    if (userSlice.userSignIn?.access_token && !auth.accessToken) {
      // setAuth({ accessToken: userSlice.userSignIn?.access_token })
      storeAccessTokenLocally(userSlice.userSignIn?.access_token)
      navigate(from, { replace: true });
    }
  }, [userSlice.status.userSignIn])


  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginValidationSchema,
    onSubmit,
  });


  return (
    <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
      <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
        <div className="flex flex-col overflow-y-auto md:flex-row">
          <div className="h-32 md:h-auto md:w-1/2 flex justify-center">
            <img src={AmrcLogo} className='w-1/2 sm:w-1/2 md:w-4/5 sm:block bg-white' />
          </div>
          <main className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
            <div className="w-full">
              <form autoComplete="off" onSubmit={handleSubmit}>
                <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">Login</h1>
                <Label>
                  <span>Email</span>
                  <Input className="mt-1" type="email" placeholder="john@doe.com"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Label>
                {errors.email &&
                  <HelperText valid={errors.email ? false : true}>
                    {errors.email}
                  </HelperText>
                }
                <Label className="mt-4">
                  <span>Password</span>
                  <Input className="mt-1" type="password" placeholder="***************"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Label>
                {errors.password &&
                  <HelperText valid={errors.password ? false : true}>
                    {errors.password}
                  </HelperText>
                }
                <Button className="mt-4" block type="submit">
                  Log in
                </Button>
                <hr className="my-8" />
                <HelperText valid={false}>
                  {userSlice.status.userSignIn === 'failed' ? `Please make sure credentials are correct and try again.` : ''}
                </HelperText>


                {/* <p className="mt-4">
                <Link
                className="text-sm font-medium text-purple-600 dark:text-purple-400 hover:underline"
                to="/forgot-password"
                >
                Forgot your password?
                </Link>
              </p> */}
                {/* <p className="mt-1">
                  <Link
                    className="text-sm font-medium text-purple-600 dark:text-purple-400 hover:underline"
                    to="/create-account"
                  >
                    Create account
                  </Link>
                </p> */}
              </form>
            </div>
          </main>
          <div>
          </div>
        </div>
        {userSlice.status.userSignIn === 'loading' &&
          <BarLoader color="#7e3af2" cssOverride={{
            width: '100vh',
            margin: 'auto'
          }} />
        }
      </div >
    </div >
  )
}

export default Login
