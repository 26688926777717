import React, { lazy } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import Layout from './containers/Layout'
import Login from './pages/Login';
import routes from './routes';
import CreateAccount from './pages/CreateAccount';
import RequireAuth from './routes/RequireAuth'
import ProceedToDashboardIfLoggedIn from './routes/ProceedToDashboardIfLoggedIn';
// const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))

function App() {
  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Routes>

          <Route element={<ProceedToDashboardIfLoggedIn />}>
            <Route path="/login" element={<Login />} />
            <Route path="/create-account" element={<CreateAccount />} />
          </Route>

          <Route element={<RequireAuth />}>
            <Route path="/app" element={<Layout />} >


              {routes.map((route, i) => {
                return route.component ? (
                  <Route
                    key={i}
                    exact={true}
                    path={`${route.path}`}
                    element={<route.component />}
                  />
                ) : null
              })}


            </Route>
          </Route>

          <Route
            path="*"
            element={<Navigate to="/login" replace />}
          />

          
        </Routes>
      </Router>
    </>
  )
}

export default App
