import React, { useContext, useState } from 'react'
import { SidebarContext } from '../context/SidebarContext'
import {
  MoonIcon,
  SunIcon,
  MenuIcon,
  OutlinePersonIcon,
  OutlineCogIcon,
  OutlineLogoutIcon,
} from '../icons'
import { Dropdown, DropdownItem, WindmillContext } from '@windmill/react-ui'
import AmrcLogo from '../assets/img/amrc-logo.svg'
import AuthContext from '../context/AuthContext'
import { resetState } from '../redux/slices/userApiSlice';
import { useDispatch, useSelector } from 'react-redux'
import { DropdownIcon } from '../icons'
import { Link, NavLink } from 'react-router-dom'

function Header() {


  const dispatch = useDispatch();
  const userSlice = useSelector((state) => state.userSlice)

  const { mode, toggleMode } = useContext(WindmillContext)
  const { toggleSidebar } = useContext(SidebarContext)

  const [isNotificationsMenuOpen, setIsNotificationsMenuOpen] = useState(false)
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false)

  const { auth, logOut } = useContext(AuthContext);



  function handleNotificationsClick() {
    setIsNotificationsMenuOpen(!isNotificationsMenuOpen)
  }


  function handleProfileClick() {
    setIsProfileMenuOpen(!isProfileMenuOpen)
  }


  const logOutUser = () => {
    logOut();
    dispatch(resetState())
  }



  return (
    <header className="z-40 py-4 bg-white shadow-bottom dark:bg-gray-800">
      <div className="container flex items-center justify-between h-full px-6 mx-auto text-purple-600 dark:text-purple-300">
        {/* <!-- Mobile hamburger --> */}
        <button
          className="p-1 mr-5 -ml-1 rounded-md lg:hidden focus:outline-none focus:shadow-outline-purple"
          onClick={toggleSidebar}
          aria-label="Menu"
        >
          <MenuIcon className="w-6 h-6" aria-hidden="true" />
        </button>
        {/* <!-- Search input --> */}
        <div className="flex justify-center flex-1 lg:mr-32">
          <img src={AmrcLogo} className='hidden sm:w-1/3 sm:block bg-white' />
        </div>
        <ul className="flex items-center flex-shrink-0 space-x-6">
          {/* <!-- Theme toggler --> */}
          <li className="flex">
            <button
              className="rounded-md focus:outline-none focus:shadow-outline-purple"
              onClick={toggleMode}
              aria-label="Toggle color mode"
            >
              {mode === 'dark' ? (
                <SunIcon className="w-5 h-5" aria-hidden="true" />
              ) : (
                <MoonIcon className="w-5 h-5" aria-hidden="true" />
              )}
            </button>
          </li>
          {/* <!-- Profile menu --> */}
          <li className="relative">
            <button
              className="rounded-full focus:shadow-outline-purple focus:outline-none"
              onClick={handleProfileClick}
              aria-label="Account"
              aria-haspopup="true"
            >
              <p className="px-3 py-2 rounded-full bg-gray-100 flex text-sm">{userSlice?.fetchOneUser?.first_name || 'User'}
                <DropdownIcon className="w-4 h-4 self-center" aria-hidden="true" />
              </p>
            </button>
            <Dropdown
              align="right"
              isOpen={isProfileMenuOpen}
              onClose={() => setIsProfileMenuOpen(false)}
            >
              <Link to='profile' relative="path">
                <DropdownItem tag="a">
                  <OutlinePersonIcon className="w-4 h-4 mr-3" aria-hidden="true" />
                  Profile
                </DropdownItem>
              </Link>
              <DropdownItem onClick={logOutUser}>
                <OutlineLogoutIcon className="w-4 h-4 mr-3" aria-hidden="true" />
                <span>Log out</span>
              </DropdownItem>
            </Dropdown>
          </li>
        </ul>
      </div>
    </header>
  )
}

export default Header
