import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../Api/api'

export const fetchCustomers = createAsyncThunk('customers/fetchCustomers', async (data) => {

    const response = await api.get(`customers?page=${parseInt(data.page)}&limit=${parseInt(data.limit)}`);
    return response.data;

});


export const fetchOneCustomer = createAsyncThunk('customers/fetchOneCustomer', async (data) => {

    const response = await api.get(`customers/${data.id}`);
    return response.data;

});


export const createCustomer = createAsyncThunk('customers/createCustomer', async (data) => {

    const response = await api.post(`customers`, data);
    return response.data;

});


export const deleteCustomer = createAsyncThunk('customers/deleteCustomer', async (data) => {

    const response = await api.delete(`customers/${data.id}`);
    return response.data;

});


export const updateCustomer = createAsyncThunk('customers/updateCustomer', async (data) => {

    const response = await api.put(`customers/${data.id}`, data.body);
    return response.data;

});



const customerSlice = createSlice({
    name: 'customers',
    initialState: {
        fetchCustomers: {},
        createCustomer: {},
        fetchOneCustomer: {},
        deleteCustomer: {},
        updateCustomer: {},
        status: {
            fetchCustomers: 'idle',
            createCustomer: 'idle',
            fetchOneCustomer: 'idle',
            deleteCustomer: 'idle',
            updateCustomer: 'idle'
        },
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCustomers.pending, (state) => {
                state.status.fetchCustomers = 'loading';
                state.error = null;
            })
            .addCase(fetchCustomers.fulfilled, (state, action) => {
                state.status.fetchCustomers = 'success';
                state.fetchCustomers = action.payload;
            })
            .addCase(fetchCustomers.rejected, (state, action) => {
                state.status.fetchCustomers = 'failed';
                state.error = action.error.message;
            })
            .addCase(createCustomer.pending, (state) => {
                state.status.createCustomer = 'loading';
                state.error = null;
            })
            .addCase(createCustomer.fulfilled, (state, action) => {
                state.status.createCustomer = 'success';
                state.createCustomer = action.payload;
            })
            .addCase(createCustomer.rejected, (state, action) => {
                state.status.createCustomer = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchOneCustomer.pending, (state) => {
                state.status.fetchOneCustomer = 'loading';
                state.error = null;
            })
            .addCase(fetchOneCustomer.fulfilled, (state, action) => {
                state.status.fetchOneCustomer = 'success';
                state.fetchOneCustomer = action.payload;
            })
            .addCase(fetchOneCustomer.rejected, (state, action) => {
                state.status.fetchOneCustomer = 'failed';
                state.error = action.error.message;
            })
            .addCase(deleteCustomer.pending, (state) => {
                state.status.deleteCustomer = 'loading';
                state.error = null;
            })
            .addCase(deleteCustomer.fulfilled, (state, action) => {
                state.status.deleteCustomer = 'success';
                state.deleteCustomer = action.payload;
            })
            .addCase(deleteCustomer.rejected, (state, action) => {
                state.status.deleteCustomer = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateCustomer.pending, (state) => {
                state.status.updateCustomer = 'loading';
                state.error = null;
            })
            .addCase(updateCustomer.fulfilled, (state, action) => {
                state.status.updateCustomer = 'success';
                state.updateCustomer = action.payload;
            })
            .addCase(updateCustomer.rejected, (state, action) => {
                state.status.updateCustomer = 'failed';
                state.error = action.error.message;
            })
    },
});

export default customerSlice.reducer