import React, { useEffect } from 'react';
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import instance from '../Api/api';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOneUser, resetState } from '../redux/slices/userApiSlice';
import { v4 as uuidv4 } from 'uuid';

const RequireAuth = () => {


    const { auth, getAccessToken, logOut } = useContext(AuthContext);
    const location = useLocation();
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchOneUser());
    }, [])


    instance.interceptors.request.use((config) => {

        // Attach the unique ID to the request headers
        config.headers['X-Request-ID'] = uuidv4();
        config.headers['Authorization'] = `Bearer ${getAccessToken()}`

        return config;
    });


    instance.interceptors.response.use(
        (response) => {
            // Handle successful responses
            return response;
        },
        (error) => {
            if (error?.response?.status === 401) {
                dispatch(resetState());
                logOut();
            }
            // Handle errors
            return Promise.reject(error);
        }
    );




    return (
        (auth?.accessToken || getAccessToken())
            ? <Outlet />
            : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;