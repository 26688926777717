import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../Api/api'


export const fetchUsers = createAsyncThunk('users/fetchUsers', async (data) => {

    const response = await api.get(`users?page=${parseInt(data.page)}&limit=${parseInt(data.limit)}`);
    return response.data;

});

export const fetchOneUser = createAsyncThunk('users/fetchOneUser', async (data) => {

    const response = await api.get(`users/me`);
    return response.data;

});

export const fetchAUser = createAsyncThunk('users/fetchAUser', async (data) => {

    const response = await api.get(`users/${data.id}`);
    return response.data;

});

export const userSignIn = createAsyncThunk('users/userSignIn', async (data) => {

    const response = await api.post(`users/sign-in`, data);
    return response.data;

});

export const userSignUp = createAsyncThunk('users/userSignUp', async (data) => {

    const response = await api.post(`users/sign-up`, data);
    return response.data;

});

export const deleteUser = createAsyncThunk('users/deleteUser', async (data) => {

    const response = await api.delete(`users/${data.id}`);
    return response.data;

});

export const updateUser = createAsyncThunk('users/updateUser', async (data) => {

    const response = await api.put(`users/${data.id}`,data.body);
    return response.data;

});


const userApiSlice = createSlice({
    name: 'users',
    initialState: {
        fetchUsers: {},
        userSignIn: {},
        fetchOneUser: {},
        userSignUp: {},
        deleteUser: {},
        fetchAUser: {},
        updateUser: {},
        status: {
            fetchUsers: 'idle',
            userSignIn: 'idle',
            fetchOneUser: 'idle',
            userSignUp: 'idle',
            deleteUser: 'idle',
            fetchAUser: 'idle',
            updateUser: 'idle'
        },
        error: null,
    },
    reducers: {
        resetState(state) {
            return {
                fetchUsers: {},
                userSignIn: {},
                fetchOneUser: {},
                status: {
                    fetchUsers: 'idle',
                    userSignIn: 'idle',
                    fetchOneUser: 'idle',
                    userSignUp: 'idle',
                    deleteUser: 'idle',
                    updateUser: 'idle'
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.status.fetchUsers = 'loading';
                state.error = null;
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.status.fetchUsers = 'success';
                state.fetchUsers = action.payload;
                state.error = null;
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.status.fetchUsers = 'failed';
                state.error = action.error.message;
            })
            .addCase(userSignIn.pending, (state) => {
                state.status.userSignIn = 'loading';
                state.error = null;
            })
            .addCase(userSignIn.fulfilled, (state, action) => {
                state.status.userSignIn = 'success';
                state.userSignIn = action.payload;
                state.error = null;
            })
            .addCase(userSignIn.rejected, (state, action) => {
                state.status.userSignIn = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchOneUser.pending, (state) => {
                state.status.fetchOneUser = 'loading';
                state.error = null;
            })
            .addCase(fetchOneUser.fulfilled, (state, action) => {
                state.status.fetchOneUser = 'success';
                state.fetchOneUser = action.payload;
                state.error = null;
            })
            .addCase(fetchOneUser.rejected, (state, action) => {
                state.status.fetchOneUser = 'failed';
                state.error = action.error.message;
            })
            .addCase(userSignUp.pending, (state) => {
                state.status.userSignUp = 'loading';
                state.error = null;
            })
            .addCase(userSignUp.fulfilled, (state, action) => {
                state.status.userSignUp = 'success';
                state.userSignUp = action.payload;
                state.error = null;
            })
            .addCase(userSignUp.rejected, (state, action) => {
                state.status.userSignUp = 'failed';
                state.error = action.error.message;
            })
            .addCase(deleteUser.pending, (state) => {
                state.status.deleteUser = 'loading';
                state.error = null;
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.status.deleteUser = 'success';
                state.deleteUser = action.payload;
                state.error = null;
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.status.deleteUser = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchAUser.pending, (state) => {
                state.status.fetchAUser = 'loading';
                state.error = null;
            })
            .addCase(fetchAUser.fulfilled, (state, action) => {
                state.status.fetchAUser = 'success';
                state.fetchAUser = action.payload;
                state.error = null;
            })
            .addCase(fetchAUser.rejected, (state, action) => {
                state.status.fetchAUser = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateUser.pending, (state) => {
                state.status.updateUser = 'loading';
                state.error = null;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.status.updateUser = 'success';
                state.updateUser = action.payload;
                state.error = null;
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.status.updateUser = 'failed';
                state.error = action.error.message;
            })

    },
});

export const { resetState } = userApiSlice.actions;


export default userApiSlice.reducer;