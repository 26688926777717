import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../Api/api'


export const fetchJobs = createAsyncThunk('jobs/fetchJobs', async (data) => {

  const response = await api.get(`jobs?page=${parseInt(data.page)}&limit=${parseInt(data.limit)}`);
  return response.data;

});

export const fetchOrderJobs = createAsyncThunk('jobs/fetchOrderJobs', async (data) => {

  const response = await api.get(`order-jobs/jobs?page=${parseInt(data.page)}&limit=${parseInt(data.limit)}&status=ALL`);
  return response.data;

});

export const fetchOneJob = createAsyncThunk('jobs/fetchOneJob', async (data) => {

  const response = await api.get(`jobs/${data.id}`);
  return response.data;

});

export const updateJob = createAsyncThunk('jobs/updateJob', async (data) => {

  const response = await api.put(`jobs/${data.id}`, data.body);
  return response.data;

});

export const createNewJob = createAsyncThunk('jobs/createJob', async (data) => {

  const response = await api.post(`jobs`, data);
  return response.data;

});

export const deleteJob = createAsyncThunk('orders/deleteJob', async (data) => {

  const response = await api.delete(`jobs/${data.id}`);
  return response.data;

});


const jobSlice = createSlice({
  name: 'jobs',
  initialState: {
    jobs: [],
    newJob: {},
    deleteJob: {},
    job: {},
    updateJob: {},
    orderJobs: {},
    status: {
      retrieveJobs: 'idle',
      createJob: 'idle',
      deleteJob: 'idle',
      fetchOneJob: 'idle',
      updateJob: 'idle',
      retrieveOrderJobs: 'idle'
    },
    error: null,
  },
  reducers: {
    setDeleteJobStatus(state, action) {
      state.status.deleteJob = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobs.pending, (state) => {
        state.status.retrieveJobs = 'loading';
        state.error = null;
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.status.retrieveJobs = 'success';
        state.jobs = action.payload;
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.status.retrieveJobs = 'failed';
        state.error = action.error.message;
      })
      .addCase(createNewJob.pending, (state) => {
        state.status.createJob = 'loading';
        state.error = null;
      })
      .addCase(createNewJob.fulfilled, (state, action) => {
        state.status.createJob = 'success';
        state.jobs = action.payload;
      })
      .addCase(createNewJob.rejected, (state, action) => {
        state.status.createJob = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteJob.pending, (state) => {
        state.status.deleteJob = 'loading';
        state.error = null;
      })
      .addCase(deleteJob.fulfilled, (state, action) => {
        state.status.deleteJob = 'success';
        state.jobs = action.payload;
      })
      .addCase(deleteJob.rejected, (state, action) => {
        state.status.deleteJob = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchOneJob.pending, (state) => {
        state.status.fetchOneJob = 'loading';
        state.error = null;
      })
      .addCase(fetchOneJob.fulfilled, (state, action) => {
        state.status.fetchOneJob = 'success';
        state.job = action.payload;
      })
      .addCase(fetchOneJob.rejected, (state, action) => {
        state.status.fetchOneJob = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateJob.pending, (state) => {
        state.status.updateJob = 'loading';
        state.error = null;
      })
      .addCase(updateJob.fulfilled, (state, action) => {
        state.status.updateJob = 'success';
        state.updateJob = action.payload;
      })
      .addCase(updateJob.rejected, (state, action) => {
        state.status.updateJob = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchOrderJobs.pending, (state) => {
        state.status.retrieveOrderJobs = 'loading';
        state.error = null;
      })
      .addCase(fetchOrderJobs.fulfilled, (state, action) => {
        state.status.retrieveOrderJobs = 'success';
        state.orderJobs = action.payload;
      })
      .addCase(fetchOrderJobs.rejected, (state, action) => {
        state.status.retrieveOrderJobs = 'failed';
        state.error = action.error.message;
      })


  },
});
export const { setDeleteJobStatus } = jobSlice.actions;

export default jobSlice.reducer;
