import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../Api/api'


export const fetchDepartments = createAsyncThunk('departments/fetchDepartments', async (data) => {

    const response = await api.get(`departments?page=${parseInt(data.page)}&limit=${parseInt(data.limit)}`);
    return response.data;

});


export const fetchOneDepartment = createAsyncThunk('departments/fetchOneDepartment', async (data) => {

    const response = await api.get(`departments/${data.id}`);
    return response.data;

});



export const createDepartment = createAsyncThunk('departments/createDepartment', async (data) => {

    const response = await api.post(`departments`, data);
    return response.data;

});



export const deleteDepartment = createAsyncThunk('departments/deleteDepartment', async (data) => {

    const response = await api.delete(`departments/${data.id}`);
    return response.data;

});



export const updateDepartment = createAsyncThunk('department/updateDepartment', async (data) => {

    const response = await api.put(`departments/${data.id}`, data.body);
    return response.data;

});

export const usersCountByDepartment = createAsyncThunk('departments/usersCountByDepartment', async (data) => {

    const response = await api.get(`departments/user-location/count`);
    return response.data;

});


export const userLocationHistory = createAsyncThunk('departments/userLocationHistory', async (data) => {

    const response = await api.get(`departments/user-location/activity?page=${parseInt(data.page)}&limit=${parseInt(data.limit)}`);
    return response.data;

});



const departmentSlice = createSlice({
    name: 'departments',
    initialState: {
        fetchDepartments: {},
        fetchOneDepartment: {},
        createDepartment: {},
        deleteDepartment: {},
        updateDepartment: {},
        usersCountByDepartment: [],
        userLocationHistory:{},
        status: {
            fetchDepartments: 'idle',
            fetchOneDepartment: 'idle',
            createDepartment: 'idle',
            deleteDepartment: 'idle',
            updateDepartment: 'idle',
            usersCountByDepartment: 'idle',
            userLocationHistory:'idle'
        },
        error: null,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDepartments.pending, (state) => {
                state.status.fetchDepartments = 'loading';
                state.error = null;
            })
            .addCase(fetchDepartments.fulfilled, (state, action) => {
                state.status.fetchDepartments = 'success';
                state.fetchDepartments = action.payload;
                state.error = null;
            })
            .addCase(fetchDepartments.rejected, (state, action) => {
                state.status.fetchDepartments = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchOneDepartment.pending, (state) => {
                state.status.fetchOneDepartment = 'loading';
                state.error = null;
            })
            .addCase(fetchOneDepartment.fulfilled, (state, action) => {
                state.status.fetchOneDepartment = 'success';
                state.fetchOneDepartment = action.payload;
                state.error = null;
            })
            .addCase(fetchOneDepartment.rejected, (state, action) => {
                state.status.fetchOneDepartment = 'failed';
                state.error = action.error.message;
            })
            .addCase(createDepartment.pending, (state) => {
                state.status.createDepartment = 'loading';
                state.error = null;
            })
            .addCase(createDepartment.fulfilled, (state, action) => {
                state.status.createDepartment = 'success';
                state.createDepartment = action.payload;
                state.error = null;
            })
            .addCase(createDepartment.rejected, (state, action) => {
                state.status.createDepartment = 'failed';
                state.error = action.error.message;
            })
            .addCase(deleteDepartment.pending, (state) => {
                state.status.deleteDepartment = 'loading';
                state.error = null;
            })
            .addCase(deleteDepartment.fulfilled, (state, action) => {
                state.status.deleteDepartment = 'success';
                state.deleteDepartment = action.payload;
                state.error = null;
            })
            .addCase(deleteDepartment.rejected, (state, action) => {
                state.status.deleteDepartment = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateDepartment.pending, (state) => {
                state.status.updateDepartment = 'loading';
                state.error = null;
            })
            .addCase(updateDepartment.fulfilled, (state, action) => {
                state.status.updateDepartment = 'success';
                state.updateDepartment = action.payload;
                state.error = null;
            })
            .addCase(updateDepartment.rejected, (state, action) => {
                state.status.updateDepartment = 'failed';
                state.error = action.error.message;
            })
            .addCase(usersCountByDepartment.pending, (state) => {
                state.status.usersCountByDepartment = 'loading';
                state.error = null;
            })
            .addCase(usersCountByDepartment.fulfilled, (state, action) => {
                state.status.usersCountByDepartment = 'success';
                state.usersCountByDepartment = action.payload;
                state.error = null;
            })
            .addCase(usersCountByDepartment.rejected, (state, action) => {
                state.status.usersCountByDepartment = 'failed';
                state.error = action.error.message;
            })
            .addCase(userLocationHistory.pending, (state) => {
                state.status.userLocationHistory = 'loading';
                state.error = null;
            })
            .addCase(userLocationHistory.fulfilled, (state, action) => {
                state.status.userLocationHistory = 'success';
                state.userLocationHistory = action.payload;
                state.error = null;
            })
            .addCase(userLocationHistory.rejected, (state, action) => {
                state.status.userLocationHistory = 'failed';
                state.error = action.error.message;
            })
    },
});

export default departmentSlice.reducer;
