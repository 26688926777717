import axios from 'axios';

const instance = axios.create({
  // baseURL: 'http://localhost:3333/api/',
  baseURL: 'https://datechs.amrcnw.co.uk/backend/api/',
});



export default instance;
