import React from 'react';
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useContext } from 'react';
import AuthContext from '../context/AuthContext';

const ProceedToDashboardIfLoggedIn = () => {


    const { auth, getAccessToken } = useContext(AuthContext);
    const location = useLocation();


    return (
        (auth?.accessToken || getAccessToken())
            ? <Navigate to="/app/dashboard" state={{ from: location }} replace />
            : <Outlet />
    );
}

export default ProceedToDashboardIfLoggedIn;