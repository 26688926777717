import { configureStore } from "@reduxjs/toolkit";
import orderReducer from './slices/orderApiSlice';
import jobReducer from './slices/jobApiSlice';
import processReducer from './slices/processApiSlice';
import customerReducer from './slices/customerSlice';
import userReducer from './slices/userApiSlice';
import departmentReducer from './slices/departmentSlice';
import dashboardReducer from './slices/dashboardSlice';
import vehicleReducer from './slices/vehicleSlice';

const store = configureStore({
    reducer: {
        orders: orderReducer,
        jobs: jobReducer,
        processes: processReducer,
        customerSlice: customerReducer,
        userSlice: userReducer,
        departmentSlice: departmentReducer,
        dashboardSlice: dashboardReducer,
        vehicleSlice: vehicleReducer
    }
})

export default store;