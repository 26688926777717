import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../Api/api'


export const fetchProcesses = createAsyncThunk('processes/fetchProcesses', async (data) => {

  const response = await api.get(`processes?page=${parseInt(data.page)}&limit=${parseInt(data.limit)}`);
  return response.data;

});

export const fetchOrderJobProcesses = createAsyncThunk('processes/fetchOrderJobProcesses', async (data) => {

  const response = await api.get(`processes/process-monitoring/all-orderjob-processes?page=${parseInt(data.page)}&limit=${parseInt(data.limit)}&status=ALL`);
  return response.data;

});

export const fetchOneProcess = createAsyncThunk('processes/fetchOneProcess', async (data) => {

  const response = await api.get(`processes/${data.id}`);
  return response.data;

});

export const updateProcess = createAsyncThunk('processes/updateProcess', async (data) => {

  const response = await api.put(`processes/${data.id}`, data.body);
  return response.data;

});


export const createNewProcess = createAsyncThunk('processes', async (data) => {

  const response = await api.post(`processes`, data);
  return response.data;

});


export const deleteProcess = createAsyncThunk('processes/deleteProcess', async (data) => {

  const response = await api.delete(`processes/${data.id}`);
  return response.data;

});



const processSlice = createSlice({
  name: 'processSlice',
  initialState: {
    processes: [],
    deleteProcess: {},
    newProcess: {},
    process: {},
    updateProcess: {},
    orderJobProcesses: {},
    status: {
      retrieveProcesses: 'idle',
      deleteProcess: 'idle',
      createProcess: 'idle',
      fetchOneProcess: 'idle',
      updateProcess: 'idle',
      fetchOrderJobProcesses: 'idle'
    },
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProcesses.pending, (state) => {
        state.status.retrieveProcesses = 'loading';
        state.error = null;
      })
      .addCase(fetchProcesses.fulfilled, (state, action) => {
        state.status.retrieveProcesses = 'success';
        state.processes = action.payload;
      })
      .addCase(fetchProcesses.rejected, (state, action) => {
        state.status.retrieveProcesses = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteProcess.pending, (state) => {
        state.status.deleteProcess = 'loading';
        state.error = null;
      })
      .addCase(deleteProcess.fulfilled, (state, action) => {
        state.status.deleteProcess = 'success';
        state.processes = action.payload;
      })
      .addCase(deleteProcess.rejected, (state, action) => {
        state.status.deleteProcess = 'failed';
        state.error = action.error.message;
      })
      .addCase(createNewProcess.pending, (state) => {
        state.status.createProcess = 'loading';
        state.error = null;
      })
      .addCase(createNewProcess.fulfilled, (state, action) => {
        state.status.createProcess = 'success';
        state.newProcess = action.payload;
      })
      .addCase(createNewProcess.rejected, (state, action) => {
        state.status.createProcess = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchOneProcess.pending, (state) => {
        state.status.fetchOneProcess = 'loading';
        state.error = null;
      })
      .addCase(fetchOneProcess.fulfilled, (state, action) => {
        state.status.fetchOneProcess = 'success';
        state.process = action.payload;
      })
      .addCase(fetchOneProcess.rejected, (state, action) => {
        state.status.fetchOneProcess = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateProcess.pending, (state) => {
        state.status.updateProcess = 'loading';
        state.error = null;
      })
      .addCase(updateProcess.fulfilled, (state, action) => {
        state.status.updateProcess = 'success';
        state.updateProcess = action.payload;
      })
      .addCase(updateProcess.rejected, (state, action) => {
        state.status.updateProcess = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchOrderJobProcesses.pending, (state) => {
        state.status.fetchOrderJobProcesses = 'loading';
        state.error = null;
      })
      .addCase(fetchOrderJobProcesses.fulfilled, (state, action) => {
        state.status.fetchOrderJobProcesses = 'success';
        state.orderJobProcesses = action.payload;
      })
      .addCase(fetchOrderJobProcesses.rejected, (state, action) => {
        state.status.fetchOrderJobProcesses = 'failed';
        state.error = action.error.message;
      })
  },
});

export default processSlice.reducer;
