import React, { createContext, useState, useMemo, useEffect } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {


    const [auth, setAuth] = useState({});


    const storeAccessTokenLocally = (token) => {
        localStorage.setItem('accessToken', token);
        setAuth({ accessToken: token})
    }


    const getAccessToken = () => {
        return localStorage.getItem('accessToken')
    }


    const logOut = () => {
        localStorage.clear();
        setAuth({ accessToken: null })
    }

    return (
        <AuthContext.Provider value={{ auth, setAuth, logOut, getAccessToken, storeAccessTokenLocally }}>
            {children}
        </AuthContext.Provider>
    )


}
export default AuthContext;